@import "./variables";

@mixin button {
    font-family: "Wadik", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 18px;
    width: max-content;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #CE7C00;
    border: 3px solid #E7BE80;
    margin: auto;
    transition: opacity 0.4s;
    letter-spacing: 1px;
    min-width: 286px;

    &:hover,
    &:active {
        opacity: 0.7;
    }
}