@import "../../assets/styles/variables";

.pager {
    height: 100vh;
    overflow-y: scroll;
}

.pagerNav {
    height: 100vh;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1650px) {
        right: 3%;
    }

    @media (max-width: $tb_bp) {
        display: none;
    }

    &::before {
        content: "";
        width: 3px;
        height: 100%;
        z-index: 2;
        background: #CE7C00;
        border: 1px solid #5F5F62;
        border-width: 0 1px;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 320px;

        @media (max-height: $dt_bp) {
            padding-bottom: 25px;
        }
    }

    &__link {
        font-family: "Pixel Cyr Normal";
        background: none;
        border: none;
        color: #fff;
        font-size: 14px;
        line-height: 28px;
        z-index: 3;
        height: 36px;
        width: 36px;
        cursor: pointer;
        transition: opacity 0.4s;
        position: relative;

        &:hover,
        &:active {
            opacity: 0.7;
        }

        &:first-child {
            margin-top: -15px;
        }

        &:last-child {
            margin-bottom: -15px;
        }

        &__pagerImage,
        &__pagerImageActive {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &__pagerImage {
            height: 36px;
            width: 10px;
            object-fit: contain;
            transition: opacity 0.7s;
        }

        &__pagerImageActive {
            height: 36px;
            width: 36px;
            transition: opacity 0.7s;
        }

        &__pagerImageActive {
            opacity: 0;
        }

        &__active {
            z-index: 2;
            font-size: 24px;
            cursor: unset;

            &:hover,
            &:active {
                opacity: 1;
            }

            .pagerNav__link__pagerImage {
                opacity: 0;
            }

            .pagerNav__link__pagerImageActive {
                opacity: 1;
            }

            &::before {
                display: block;
                content: "";
                position: absolute;
                bottom: 16px;
                left: 50%;
                transform: translateX(-50%);
                width: 3px;
                height: 800px;
                z-index: 0;
                background: #CE7C00;
                border: 1px solid #5F5F62;
                border-width: 0 1px;
            }
        }
    }

    &__bgLine {
        content: "";
        width: 5px;
        height: 100%;
        position: absolute;
        z-index: 1;
        background: rgba(255, 255, 255, 0.30);


    }

    &::after {
        content: "";
        height: 100%;
    }
}