@import "../../assets/styles/mixins";

.aboutVideo {
    padding: 70px 0 120px;
    position: relative;
    overflow: hidden;

    @media (max-width: $tb_bp) {
        padding-bottom: 80px;
    }

    @media (max-width: $sp_bp) {
        padding-bottom: 50px;
    }

    &__title {
        font-family: "Wadik", sans-serif;
        font-size: 56px;
        color: #fff;
        margin: 45px 0 40px;

        @media (max-width: $xdt_bp) {
            font-size: 40px;
            margin-top: 75px;
        }

        @media (max-width: $tb_bp) {
            margin-top: 40px;
        }

        @media (max-width: $sp_bp) {
            margin-top: 35px;
            font-size: 36px;
        }

        @media (max-width: $xs_bp) {
            font-size: 26px;
        }
    }

    &__link {
        @include button;
        margin: 0;

        @media (max-width: $tb_bp) {
            margin: auto;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        position: relative;

        @media (max-width: $tb_bp) {
            flex-direction: column-reverse;
        }

        @media (max-width: $sp_bp) {
            padding: 0;
        }


        &__desc {
            width: 525px;

            @media (max-width: $xdt_bp) {
                width: 360px;
            }

            @media (max-width: $tb_bp) {
                width: 100%;
                text-align: center;
            }
        }

        &__video {
            padding: 63px 0 0 15px;

            @media (max-width: $dt_bp) {
                padding: 35px 0 0 15px;
            }

            @media (max-width: $tb_bp) {
                padding: 0;
            }

            img {
                max-width: 560px;
                cursor: pointer;

                @media (max-width: $tb_bp) {
                    width: 100%;
                }
            }
        }

        &__iframe {
            height: 435px;
            outline: 2px solid #CE7C00;

            @media (max-width: $dt_bp) {
                width: 490px;
            }

            @media (max-width: $tb_bp) {
                width: 100%;
            }

            @media (max-width: $sp_bp) {
                height: 400px;
            }

            @media (max-width: $xs_bp) {
                height: 280px;
            }

            iframe {
                @media (max-width: $dt_bp) {
                    width: 100%;
                }

                @media (max-width: $sp_bp) {
                    height: 100%;
                }
            }
        }
    }

    &__smoke {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: -10px;
        animation: moveLeftRight 10s ease-in-out infinite;
    }

    &__bg {
        position: absolute;
        width: 100%;
        bottom: -5px;
        animation: moveUpDown 6s ease-in-out infinite;
    }

    @keyframes moveUpDown {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(10px);
        }
    }

    @keyframes moveLeftRight {

        0%,
        100% {
            transform: translateX(-1px);
        }

        50% {
            transform: translateX(10px);
        }
    }
}