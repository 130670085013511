@import "../../assets/styles/mixins";

.header {
    position: relative;
    width: 100%;
    font-size: 50px;
    position: relative;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 0 100px;

    @media (max-width: $dt_bp) {
        padding: 130px 0 50px;
    }

    @media (max-width: $tb_bp) {
        padding: 115px 0 50px;
    }

    @media (max-width: $sp_bp) {
        height: auto;
        padding: 115px 0 40px;
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 810px; // 612px
        text-align: center;
        padding-top: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media (max-width: $sp_bp) {
            width: 100%;
            padding: 10px 40px;
            max-width: 560px;
            min-height: 450px;
        }

        @media (max-width: $xs_bp) {
            padding: 10px 20px;
            max-width: 320px;
            min-height: 500px;
        }

        &__logo {
            width: 64px;

            @media (max-width: $sp_bp) {
                width: 43px;
            }
        }

        &__title {
            font-family: "Wadik", sans-serif;
            font-size: 96px;
            margin: 40px 0 50px 0;
            line-height: 100%;

            @media (max-width: $dt_bp) {
                font-size: 76px;
            }

            @media (max-width: $sp_bp) {
                font-size: 50px;
            }

            @media (max-width: $xs_bp) {
                font-size: 38px;
            }
        }

        &__text {
            font-size: 25px;
            line-height: 40px;
            font-weight: 400;
            margin: 0 0 15px;
            text-align: center;
            max-width: 764px;

            @media (max-width: $dt_bp) {
                font-size: 23px;
            }

            @media (max-width: $sp_bp) {
                font-size: 22px;
                line-height: 32px;
            }
        }

        &__link {
            @include button;
            margin-top: 17px;
        }
    }

    &__bg {
        position: absolute;
        width: 100%;
        top: 0;
        animation: moveUpDown 7s ease-in-out infinite;
    }

    @keyframes moveUpDown {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(10px);
        }
    }
}