@import "../../assets/styles/mixins";

.features {
    padding: 89px 0 116px;
    position: relative;

    @media (max-width: $tb_bp) {
        padding: 60px 0 80px;
    }

    @media (max-width: $sp_bp) {
        padding: 40px 0 60px;
    }

    &__items {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__item {
        width: 48.72%;
        background: linear-gradient(180deg, #1B1A1F 52.45%, #2C2A32 100%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        margin-bottom: 26px;

        @media (max-width: $sp_bp) {
            width: 100%;
            outline: 2px solid #CE7C00;
        }

        &:hover {
            @media (min-width: $sp_bp) {
                outline: 3px solid #CE7C00;
                background: rgba(255, 255, 255, 0.10);
            }
        }
    }

    &__img {
        width: 100%;
    }

    &__desc {
        min-height: 245px;
        padding: 44px 47px;
        color: #fff;
    }

    &__title {
        font-family: "Wadik", sans-serif;
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
        margin: 0;
    }

    &__text {
        font-size: 20px;
        line-height: 32px;
        margin: 17px 0 0;
    }

    &__link {
        @include button;
        position: relative;
        margin-top: 4px;
    }

    &__bg {
        position: absolute;
        width: 100%;
        bottom: -5px;
        animation: moveUpDown 7s ease-in-out infinite;
    }

    @keyframes moveUpDown {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(10px);
        }
    }
}