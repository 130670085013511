@import "../../assets/styles/variables";

.spinner {
    width: max-content;
    margin: auto;
    font-size: 17px;
    color: #CE7C00;

    &__element {
        width: 25px;
        height: 25px;
        border: 5px solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        margin-top: 4px;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &__component {
        display: block;
        margin: 50px 0 100px;
        font-size: 32px;
    }
}