@import "../../assets/styles/variables";

.container {
    max-width: 1230px;
    padding: 0 30px;
    margin: 0 auto;

    @media (max-width: $xdt_bp) {
        max-width: 1060px;
    }

    @media (max-width: $dt_bp) {
        max-width: 890px;
    }

    @media (max-width: $tb_bp) {
        max-width: 730px;
    }

    @media (max-width: $sp_bp) {
        padding: 0 20px;
    }
}
