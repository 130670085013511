@import "../../assets/styles/variables";

.footer {
    background: linear-gradient(106deg, rgba(43, 43, 43, 0.27) 17.82%, rgba(181, 169, 145, 0.27) 68.41%);
    padding: 40px 0 70px;

    @media (max-width: $sp_bp) {
        padding: 30px 0;
    }

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 50px 10px 0 43px;

        @media (max-width: $dt_bp) {
            padding: 50px 0 0 0;
        }

        @media (max-width: $sp_bp) {
            align-items: center;
            flex-direction: column;
        }
    }

    &__leftWrap {
        width: 60%;

        @media (max-width: $tb_bp) {
            width: 70%;
        }

        @media (max-width: $sp_bp) {
            width: 100%;
        }
    }

    &__menu {
        max-width: 100%;
        text-align: center;

        @media (max-width: $sp_bp) {
            top: 11px;
            margin: auto;
        }

        @media (max-width: $xs_bp) {
            width: 320px;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;

            @media (max-width: $sp_bp) {
                justify-content: center;
            }
        }

        &__item {
            margin-right: 50px;

            @media (max-width: $xdt_bp) {
                margin-right: 40px;
            }

            @media (max-width: $dt_bp) {
                margin-right: 28px;
            }

            @media (max-width: $tb_bp) {
                margin-right: 22px;
            }

            @media (max-width: $sp_bp) {
                line-height: 32px;
                margin: 0 11px;
            }

            a {
                font-family: "Wadik", sans-serif;
                font-size: 18px;
                font-weight: bold;
                color: white;
                text-decoration: none;
                vertical-align: middle;
                white-space: nowrap;
                transition: all 0.2s;

                @media (max-width: $xdt_bp) {
                    font-size: 16px;
                }

                @media (max-width: $sp_bp) {
                    font-size: 16px;
                }

                &:hover {
                    opacity: .8;
                }
            }

            span {
                color: #CE7C00;
            }
        }
    }

    &__nav {
        &__link {
            font-family: "Roboto";
            font-size: 18px;
            line-height: 19px;
            color: rgba(255, 255, 255, 0.8);
            margin-right: 40px;
            text-decoration: none;

            @media (max-width: $xs_bp) {
                margin-right: 22px;
            }

            &:last-child {
                @media (max-width: $sp_bp) {
                    margin-right: 0;
                }
            }

            &:hover,
            &:active {
                text-decoration: underline;
            }
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        margin: 40px 0 22px 0px;

        @media (max-width: $sp_bp) {
            flex-direction: column;
            margin: 34px 0 8px;
            justify-content: center;
        }

        &__img {
            margin-right: 20px;
            width: 64px;
            height: 64px;

            @media (max-width: $sp_bp) {
                margin-right: 10px;
            }
        }

        &__text {
            font-family: "Wadik", sans-serif;
            font-size: 52px;
            line-height: 110%;
            color: #ffffff;
            margin: 0 0 2px;

            @media (max-width: $dt_bp) {
                font-size: 46px;
            }

            @media (max-width: $sp_bp) {
                margin: 16px 0 10px;
                font-size: 36px;
            }

            @media (max-width: $xs_bp) {
                font-size: 32px;
            }
        }
    }

    &__text {
        width: 100%;
        font-family: "Roboto";
        font-size: 18px;
        line-height: 150%;
        color: rgba(255, 255, 255, 0.5);
        margin: 0 7px 0;

        @media (max-width: $sp_bp) {
            margin-top: 45px;
            text-align: center;
        }
    }

    &__links {
        margin: 0 7px 0;

        &__link {
            font-family: "Roboto";
            font-size: 18px;
            line-height: 150%;
            color: rgba(255, 255, 255, 0.5);
            padding: 0 6px;
            border-left: 1px solid rgba(255, 255, 255, 0.5);
            text-decoration: none;

            @media (max-width: $xs_bp) {
                font-size: 16px;
            }

            &:first-child {
                padding-left: 0;
                border: 0;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        @media (max-width: $sp_bp) {
            text-align: center;
        }
    }

    &__social {
        width: 40%;
        text-align: right;
        padding-top: 2px;

        @media (max-width: $tb_bp) {
            width: 30%;
        }

        @media (max-width: $sp_bp) {
            width: 100%;
            margin-top: 25px;
            text-align: center;
        }

        i {
            font-size: 20px;
            cursor: pointer;

            &:first-child {
                @media (max-width: $sp_bp) {
                    margin-left: 0;
                }
            }

            &::before {
                transition: all 0.2s;
            }
        }

        a {
            margin-left: 20px;
            text-decoration: none;
            font-size: 20px;

            @media (max-width: $sp_bp) {
                margin: 0 10px;
            }

            &:hover i::before {
                opacity: .8;
            }
        }
    }
}