@font-face {
  font-family: "icomoon";
  src: url("../icon-fonts/icomoon.eot?n4aa43");
  src: url("../icon-fonts/icomoon.eot?n4aa43#iefix") format("embedded-opentype"),
    url("../icon-fonts/icomoon.ttf?n4aa43") format("truetype"), url("../icon-fonts/icomoon.woff?n4aa43") format("woff"),
    url("../icon-fonts/icomoon.svg?n4aa43#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrowSlider:before {
  content: "\e90f";
  color: #fff;
}
.icon-socialTwitter:before {
  content: "\e900";
  color: #fff;
}
.icon-social1:before {
  content: "\e901";
  color: #fff;
}
.icon-socialTelegram:before {
  content: "\e902";
  color: #fff;
}
.icon-social2:before {
  content: "\e903";
  color: #fff;
}
.icon-social3:before {
  content: "\e904";
  color: #fff;
}
.icon-socialDiscord:before {
  content: "\e905";
  color: #fff;
}
.icon-social4:before {
  content: "\e906";
  color: #fff;
}
.icon-bgStar1:before {
  content: "\e907";
  color: #fff;
}
.icon-bgStar2:before {
  content: "\e908";
  color: #fff;
}
.icon-bgStar3:before {
  content: "\e909";
  color: #fff;
}
.icon-bgStar4:before {
  content: "\e90a";
  color: #fff;
}
.icon-bgStar5:before {
  content: "\e90b";
  color: #fff;
}
.icon-bgStar6:before {
  content: "\e90c";
  color: #fff;
}
.icon-bgStar7:before {
  content: "\e90d";
  color: #fff;
}
.icon-bgStar8:before {
  content: "\e90e";
  color: #fff;
}
