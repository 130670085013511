@import "../../assets/styles/mixins";

.contact {
    padding: 50px 0 100px;
    position: relative;
    color: #fff;

    @media (max-width: $tb_bp) {
        padding-top: 40px;
    }

    &__title {
        font-family: "Wadik", sans-serif;
        max-width: 1170px;
        margin: 0 auto 32px;
        text-align: center;
        font-size: 56px;
        line-height: 110%;

        @media (max-width: $dt_bp) {
            font-size: 46px;
        }

        @media (max-width: $sp_bp) {
            font-size: 36px;
        }

        @media (max-width: $xs_bp) {
            font-size: 26px;
        }
    }

    &__desc {
        text-align: center;
        font-size: 20px;
        line-height: 32px;
    }

    &__form {
        display: flex;
        justify-content: space-between;
        max-width: 645px;
        margin: auto;
        padding-top: 65px;

        @media (max-width: $sp_bp) {
            padding-top: 35px;
            flex-direction: column;
        }
    }

    &__input {
        font-family: 'Roboto', sans-serif;
        height: 50px;
        width: 100%;
        margin-right: 14px;
        padding: 10px 0 12px;
        background: transparent;
        border: none;
        border-bottom: 1px solid #fff;
        color: #fff;
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
        outline: none;

        @media (max-width: $sp_bp) {
            margin-bottom: 15px;
        }

        &::placeholder {
            font-family: "Wadik", sans-serif;
            color: #fff;
            opacity: 1;
            font-size: 20px;
            line-height: 20px;
        }

        &:focus {
            outline: none;

            &::placeholder {
                color: #e2e2e2;
            }
        }
    }

    &__error {
        position: absolute;
        font-size: 15px;
        font-weight: 500;
        color: #CE7C00;
        text-align: center;
        margin-top: 15px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__button {
        font-family: "Wadik", sans-serif;
        background-color: #CE7C00;
        border: 3px solid #E7BE80;
        min-width: 286px;
        height: 50px;
        font-size: 14px;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
    }

    &__buttonLoading {
        opacity: .8;
    }
}