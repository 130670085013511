@import "../../assets/styles/index.scss";

.headerMenu {
    position: absolute;
    top: 55px;
    z-index: 2;
    width: 900px; // 612px
    max-width: 100%;
    text-align: center;

    @media (max-width: $sp_bp) {
        width: 100%;
        top: 0;
    }

    &__mobile {
        display: none;

        @media (max-width: $sp_bp) {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            height: 65px;
            border-bottom: 1px solid #CE7C00;
        }

        a {
            font-family: "Wadik", sans-serif;
            display: flex;
            align-items: center;
            text-decoration: none;
        }

        img {
            margin: 0 10px;
            height: 30px;
            width: 30px;
        }

        p {
            margin: 0;
            font-weight: 700;
            font-size: 24px;
            line-height: 110%;
            color: #ffffff;
        }

        button {
            cursor: pointer;
            padding: 10px;
            width: 48px;
            height: 47px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: space-between;
            background-color: transparent;
            border: none;

            span {
                transition: all 0.3s;
                display: block;
                background: #CE7C00;
                width: 11px;
                height: 11px;

                transform: rotate(180deg);
            }
        }
    }

    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;

        @media (max-width: $sp_bp) {
            display: none;
        }
    }

    &__item {
        position: relative;
        height: 70px;
        display: flex;
        align-items: center;
        margin-right: 50px;
        line-height: 18px;

        @media (max-width: $sp_bp) {
            line-height: 30px;
            margin: 0 11px;
        }

        &:last-child {
            margin-right: 20px;
        }

        &:hover> {
            @media (min-width: $sp_bp) {
                ul {
                    display: block;
                }
            }

            a {
                img {
                    transform: rotateX(0);
                }
            }
        }

        .headerMenu__arrow {
            display: none;

            @media (min-width: $sp_bp) {
                display: block;
                transition: transform 0.2s;
                transform: rotateX(180deg);
                margin: 8px 0 0 8px;
            }
        }

        a {
            font-family: "Wadik", sans-serif;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            font-weight: bold;
            color: white;
            text-decoration: none;
            vertical-align: middle;
            white-space: nowrap;
            transition: all 0.2s;

            @media (max-width: $sp_bp) {
                font-size: 16px;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        span {
            color: #CE7C00;
        }
    }

    &__discord {
        transition: all 0.2s;

        &:hover {
            opacity: 0.8;
        }
    }

    &__discord,
    &__discord img {
        display: inline-block;

        i {
            display: none;
        }
    }
}

.activeMenu {
    position: fixed;
    z-index: 999;
    overflow-y: scroll;
    height: 100%;
    background: $main_color;

    .headerMenu {
        &__mobile {
            justify-content: space-between;
            margin: auto;

            button {
                position: relative;

                span {
                    &:nth-child(2n) {
                        display: none;
                    }

                    &:nth-child(2n + 1) {
                        height: 30px;
                        width: 6px;
                    }

                    &:nth-child(1) {
                        transform: translate(11px, -1px) rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: translate(-11px, -1px) rotate(-45deg);
                    }
                }
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            margin: auto;
            padding: 50px;
        }

        &__item {
            width: 100%;
            margin: 0;
            min-height: 44px;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: baseline;

            a {
                color: #fff;
                text-shadow: none;
                font-size: 16px;
                line-height: 19px;
            }

            ul {
                position: static;
                display: block;
                background-color: transparent;
                border-left: 1px solid #CE7C00;
                padding: 0 0 0 14px;
                margin: 18px 0 28px;

                &::before {
                    display: none;
                }

                a {
                    justify-content: flex-start;
                }

                li {
                    margin: 0 0 18px;
                    height: 16px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        &__discord {
            margin-top: 42px;
            width: 100%;
            justify-content: flex-end;

            img {
                display: none;
            }

            i {
                display: block;
                font-size: 61px;

                &::before {
                    color: #fff;
                }
            }
        }

        &__arrow {
            display: block;
            margin: 8px 5px 2px 16px;
        }

        &__itemParent>a {
            color: #CE7C00;
        }
    }
}